import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
// import Layout from "../components/layout/layout";
import Layout from '../components/layout/layout'
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby";
import ArticlePreview from "../components/article-preview";



const Gallery = () => {

    const query = useStaticQuery(graphql`
    query GalleryQuery {
        allContentfulYearEntry(sort: { fields: [year], order: DESC }) {
          edges {
            node {
              year
              childContentfulYearEntryDescriptionTextNode {
                childMarkdownRemark {
                  html
                }
              }
              assets {
                fluid(maxWidth: 1250, maxHeight: 1500) {
                  ...GatsbyContentfulFluid_tracedSVG
                }
                title
              }
            }
          }
        }
        allContentfulAsset(limit: 100) {
          edges {
            node {
              title
              fluid {
                src
              }
            }
          }
        }
      }
  `);
//   fluid(maxWidth: 1980, maxHeight: 1280) {


    const entries = query.allContentfulYearEntry.edges
      console.log(entries)
    const assets = query.allContentfulAsset.edges
    return (
        <Layout>
            <h2 style={{padding: '1rem', fontSize: '60px', textAlign: 'center'}} >The Roasts of Old</h2>
        <ArticlePreview entries={entries} />
        <section style={{maxWidth: '1000px', margin: '2rem auto'}}>
        <div className='gallery-photo-grid' style={{justifyItems: 'center'}} >
          <iframe width="100%" height="300" style={{borderRadius: '35px', padding: '1rem'}} src="https://www.youtube.com/embed/RGQ4r9xul9A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe width="100%" height="300" style={{borderRadius: '35px', padding: '1rem'}} src="https://www.youtube.com/embed/sOrZ6LJsIuo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          {/* {assets.map(asset => {
            return (
              <Img style={{minHeight: '300px'}} alt={asset.node.title} fluid={asset.node.fluid} />
            )
          })} */}
        </div>
        </section>
        </Layout>
    )
}

export default Gallery
